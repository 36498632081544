import {
  CheckIcon,
  CloseButton,
  Combobox,
  Group,
  Input,
  InputWrapperProps,
  TextInput,
  useCombobox,
} from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { EMPTY_STRING } from "src/utils/empty";
import { DropdownOption, TEXT_INPUT_STYLES } from "./constants";

interface Props extends Omit<InputWrapperProps, "onChange"> {
  readonly disabled?: boolean;
  readonly options: ReadonlyArray<DropdownOption>;
  readonly displayClassName: string | undefined;
  readonly placeholders: {
    readonly active: string;
    readonly inactive: string;
  };
  readonly value?: string; // TODO: make this required once hooked up to estimation table
}

export const DropdownCell = React.memo<Props>(function _DropdownCell({
  disabled = false,
  options,
  displayClassName,
  placeholders,
  value = EMPTY_STRING, // TODO: remove default value once hooked up to estimation table
  ...inputWrapperProps
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState(value);
  const [isEdit, setIsEdit] = React.useState(false);

  const selectedOption = React.useMemo(() => {
    return !isEdit
      ? options.find((option) => option.label === search)
      : undefined;
  }, [isEdit, options, search]);

  const isSelected = selectedOption != null;
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const filteredOptions = React.useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase().trim()),
    );
  }, [options, search]);

  const handleDropdownOpen = React.useCallback(() => {
    setIsEdit(true);
    combobox.openDropdown();
  }, [combobox]);

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
      combobox.openDropdown();
      combobox.updateSelectedOptionIndex();
    },
    [combobox],
  );

  const handleClear = React.useCallback(() => {
    setSearch(EMPTY_STRING);
    combobox.openDropdown();
  }, [combobox]);

  const handleOptionSubmit = React.useCallback(
    (submittedValue: string) => {
      setSearch(submittedValue);
      combobox.closeDropdown();
      setIsEdit(false);

      // TODO: do API call here
    },
    [combobox],
  );

  const handleSearchBlur = React.useCallback(() => {
    combobox.closeDropdown();
    setIsEdit(false);
    if (options.find((option) => option.label === search) == null) {
      setSearch(EMPTY_STRING);
    }

    // TODO: do API call here
  }, [combobox, options, search]);

  const handleEditSelection = React.useCallback(() => {
    setIsEdit(true);
  }, []);

  React.useEffect(() => {
    // we need to wait for options to render before we can select first one
    combobox.selectFirstOption();
  }, [combobox, value]);

  React.useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }
  }, [isEdit]);

  if (isSelected) {
    return (
      <Group
        className={classNames(displayClassName, selectedOption.className)}
        gap="xs"
        onClick={handleEditSelection}
      >
        {selectedOption.icon ?? undefined}
        {selectedOption.label}
      </Group>
    );
  }

  return (
    <Input.Wrapper {...inputWrapperProps}>
      <Combobox onOptionSubmit={handleOptionSubmit} store={combobox}>
        <Combobox.Target>
          <TextInput
            ref={inputRef}
            disabled={disabled}
            onBlur={handleSearchBlur}
            onChange={handleSearchChange}
            onFocus={handleDropdownOpen}
            placeholder={!isEdit ? placeholders.inactive : placeholders.active}
            rightSection={
              search !== EMPTY_STRING &&
              !disabled &&
              isEdit && (
                <CloseButton
                  aria-label="Clear value"
                  onClick={handleClear}
                  // prevent other fields from losing focus if user interacts with CloseButton while on another field
                  onMouseDown={PREVENT_DEFAULT}
                  size="sm"
                />
              )
            }
            rightSectionPointerEvents={search !== EMPTY_STRING ? "all" : "none"}
            styles={TEXT_INPUT_STYLES}
            value={search}
            variant={isEdit ? "default" : "unstyled"}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((item) => (
                <Combobox.Option key={item.label} value={item.label}>
                  <Group gap="xs">
                    {item.label === search ? (
                      <CheckIcon size={12} />
                    ) : (
                      item.icon
                    )}
                    {item.label}
                  </Group>
                </Combobox.Option>
              ))
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Input.Wrapper>
  );
});
