import { Menu, Tabs } from "@mantine/core";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "src/data/api/api";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import EditableInput from "./EditableInput";

interface Props {
  packageTab: PackageId;
}

const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

const PackageTab = ({ packageTab }: Props) => {
  const [searchParams] = useSearchParams();
  const packageId = searchParams.get("packageId");
  const [opened, setOpened] = useState(false);
  const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [editable, setEditable] = useState(false);

  const [updatePackage] = api.endpoints.updatePackage.useMutation();
  const [deletePackage] = api.endpoints.deletePackage.useMutation();

  const handleContextMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (packageId === packageTab.id.toString()) {
        e.preventDefault();

        const { pageX, pageY } = e;
        setOpened(true);
        setContextMenu({
          show: true,
          x: pageX,
          y: pageY,
        });
      }
    },
    [packageId, packageTab.id],
  );

  const handleSubmit = useCallback(
    (title: string) => {
      updatePackage({
        id: packageTab.id.toString(),
        package: {
          title,
        },
      }).then(() => setEditable(false));
    },
    [packageTab.id, updatePackage],
  );

  const handleDeletePackage = useCallback(() => {
    if (packageId != null) {
      deletePackage(packageId);
    }
  }, [deletePackage, packageId]);

  const handleRename = useCallback(() => {
    setEditable(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleBlur = useCallback(() => {
    setEditable(false);
  }, []);

  return (
    <Menu
      arrowPosition="center"
      closeOnItemClick
      onClose={handleClose}
      opened={opened}
      position="right-start"
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <Tabs.Tab
          onContextMenu={handleContextMenu}
          value={packageTab.id.toString()}
        >
          <EditableInput
            isEditable={editable}
            onBlur={handleBlur}
            onSubmit={handleSubmit}
            value={packageTab.title || packageTab.id.toString()}
          />
        </Tabs.Tab>
      </Menu.Target>

      {contextMenu.show ? (
        <Menu.Dropdown>
          <Menu.Item onClick={handleRename}>Rename</Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={handleDeletePackage}>Delete</Menu.Item>
        </Menu.Dropdown>
      ) : null}
    </Menu>
  );
};

export default PackageTab;
