import { EMPTY_STRING } from "./empty";

export function formatNumericValue(
  value: number | undefined,
  decimalPlaces: number = 0,
): string {
  if (value == null) {
    return EMPTY_STRING;
  }

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
}
