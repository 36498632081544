import { ColDef, ValueGetterParams } from "ag-grid-community";
import React from "react";
import { getDropdownOptions } from "../../getDropdownOptions";
import {
  DROPDOWN_PLACEHOLDER,
  DropdownType,
  UNIT_DISPLAYS,
  UnitPosition,
  valueDisplayStyles,
} from "../constants";
import { DescriptionCell } from "../DescriptionCell";
import { DropdownCell } from "../DropdownCell";
import { ValueDisplayCell } from "../ValueDisplayCell";

// import CostTypes from "../../CostTypes";

export interface DummyPackage {
  readonly costType: string;
  readonly trade: string;
  readonly description: string;
  readonly quantity: {
    readonly value?: number;
    readonly unit: string;
  };
  readonly unitCost?: number;
  readonly subtotal?: number;
  readonly labor: {
    readonly trade: string;
    readonly description: string;
    readonly hours?: number;
    readonly totalHours?: number;
  };
}

export function useEstimationColumnDefById() {
  return React.useMemo((): ReadonlyArray<ColDef<unknown>> => {
    return [
      {
        headerName: "COST TYPE",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          const { displayClassName, options } = getDropdownOptions(
            DropdownType.CostType,
          );

          return (
            <DropdownCell
              displayClassName={displayClassName}
              options={options}
              placeholders={DROPDOWN_PLACEHOLDER[DropdownType.CostType]}
              value={v.data?.costType}
            />
          );
        },
      },
      {
        headerName: "TRADE",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          const { displayClassName, options } = getDropdownOptions(
            DropdownType.Trade,
          );
          return (
            <DropdownCell
              displayClassName={displayClassName}
              options={options}
              placeholders={DROPDOWN_PLACEHOLDER[DropdownType.CostType]}
              value={v.data?.trade}
            />
          );
        },
      },
      {
        headerName: "DESCRIPTION",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          switch (v.data?.costType) {
            case DropdownType.DescEngineering: {
              const { displayClassName, options } = getDropdownOptions(
                DropdownType.DescEngineering,
              );
              return (
                <DropdownCell
                  displayClassName={displayClassName}
                  options={options}
                  placeholders={
                    DROPDOWN_PLACEHOLDER[DropdownType.DescEngineering]
                  }
                  value={v.data?.description}
                />
              );
            }

            case DropdownType.DescSubcontractor: {
              const { displayClassName, options } = getDropdownOptions(
                DropdownType.DescSubcontractor,
              );
              return (
                <DropdownCell
                  displayClassName={displayClassName}
                  options={options}
                  placeholders={
                    DROPDOWN_PLACEHOLDER[DropdownType.DescSubcontractor]
                  }
                  value={v.data?.description}
                />
              );
            }

            default:
              return <DescriptionCell value={v.data?.description} />;
          }
        },
      },
      {
        headerName: "QTY",
      },
      {
        headerName: "UNIT COST",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          return (
            <ValueDisplayCell
              precision={2}
              unitDisplay={UNIT_DISPLAYS.DOLLAR}
              unitPosition={UnitPosition.Prefix}
              value={v.data?.unitCost}
            />
          );
        },
      },
      {
        headerName: "SUBTOTAL",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          return (
            <ValueDisplayCell
              displayClassName={valueDisplayStyles}
              precision={2}
              unitDisplay={UNIT_DISPLAYS.DOLLAR}
              unitPosition={UnitPosition.Prefix}
              value={v.data?.subtotal}
            />
          );
        },
      },
      {
        headerName: "TRADE",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          const { displayClassName, options } = getDropdownOptions(
            DropdownType.Trade,
          );
          return (
            <DropdownCell
              displayClassName={displayClassName}
              options={options}
              placeholders={DROPDOWN_PLACEHOLDER[DropdownType.CostType]}
              value={v.data?.trade}
            />
          );
        },
      },
      {
        headerName: "DESCRIPTION",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          return <DescriptionCell value={v.data?.labor.description} />;
        },
      },
      {
        headerName: "HOURS",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          return (
            <ValueDisplayCell
              unitDisplay={UNIT_DISPLAYS.HOURS}
              unitPosition={UnitPosition.Suffix}
              value={v.data?.labor.hours}
            />
          );
        },
      },
      {
        headerName: "TOTAL HOURS",
        cellRenderer: (v: ValueGetterParams<DummyPackage>) => {
          return (
            <ValueDisplayCell
              unitDisplay={UNIT_DISPLAYS.HOURS}
              unitPosition={UnitPosition.Suffix}
              value={v.data?.labor.totalHours}
            />
          );
        },
      },
    ];
  }, []);
}
