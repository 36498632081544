import { TextInputProps } from "@mantine/core";
import { LabelValue } from "src/types/util/labelValue";
import { ValuesUnion } from "src/types/valuesUnion";
import dropdownStyles from "./DropdownCell.module.scss";
import valueStyles from "./ValueDisplayCell.module.scss";

export const DropdownType = {
  CostType: "Cost type",
  DescSubcontractor: "Subcontractor",
  DescEngineering: "Engineering",
  Trade: "Trade",
} as const;
export type DropdownType = ValuesUnion<typeof DropdownType>;

type DropdownPlaceholders = {
  readonly [key in DropdownType]: {
    readonly active: string;
    readonly inactive: string;
  };
};

export const DROPDOWN_PLACEHOLDER: DropdownPlaceholders = {
  [DropdownType.CostType]: {
    active: "Cost type",
    inactive: "Select",
  },
  [DropdownType.Trade]: {
    active: "Trades",
    inactive: "Select",
  },
  [DropdownType.DescSubcontractor]: {
    active: "Description",
    inactive: "Select",
  },
  [DropdownType.DescEngineering]: {
    active: "Description",
    inactive: "Select",
  },
};

export const UnitPosition = {
  Prefix: "Prefix",
  Suffix: "Suffix",
} as const;
export type UnitPosition = ValuesUnion<typeof UnitPosition>;

export const DEFAULT_PLACEHOLDER = "-";
export const UNIT_DISPLAYS = {
  DOLLAR: "$",
  HOURS: "hrs",
};

export const valueDisplayStyles: Readonly<string | undefined> =
  valueStyles.subtotal;

export const TEXT_INPUT_STYLES: TextInputProps["styles"] = {
  input: {
    borderRadius: 0,
  },
};

type DropdownDisplayStyles = Readonly<Record<DropdownType, string | undefined>>;

export const dropdownDisplayStyles: DropdownDisplayStyles = {
  [DropdownType.CostType]: dropdownStyles.displayCostType,
  [DropdownType.Trade]: dropdownStyles.displayTrade,
  [DropdownType.DescEngineering]: dropdownStyles.displayDescription,
  [DropdownType.DescSubcontractor]: dropdownStyles.displayDescription,
};

export interface DropdownOption extends LabelValue {
  readonly className?: string | undefined;
  readonly icon?: JSX.Element;
}

export const TRADE_OPTIONS: ReadonlyArray<DropdownOption> = [
  {
    label: "Quoted items",
    value: "QUOTED_ITEMS",
  },
  {
    label: "Sheet metal fabrication",
    value: "SHEET_METAL_FAB",
  },
  {
    label: "Sheet metal",
    value: "SHEET_METAL",
  },
  {
    label: "Piping",
    value: "PIPING",
  },
  {
    label: "Control",
    value: "CONTROL",
  },
  {
    label: "Plumbing",
    value: "PLUMBING",
  },
  {
    label: "Service",
    value: "SERVICE",
  },
  {
    label: "Subcontracts",
    value: "SUBCONTRACTS",
  },
  {
    label: "Engineering",
    value: "ENGINEERING",
  },
  {
    label: "Warehouse",
    value: "WAREHOUSE",
  },
  {
    label: "Project management",
    value: "PROJECT_MANAGEMENT",
  },
  {
    label: "Quote",
    value: "QUOTE",
  },
  {
    label: "Other expenses",
    value: "OTHER_EXPENSES",
  },
];

export const DESC_ENGINEERING_OPTIONS: ReadonlyArray<DropdownOption> = [
  {
    label: "Mechanical engineering",
    value: "MECHANICAL_ENG",
  },
  {
    label: "Electrical engineering",
    value: "ELECTRICAL_ENG",
  },
  {
    label: "Structural engineering",
    value: "STRUCTURAL_ENG",
  },
  {
    label: "Plumbing engineering",
    value: "PLUMBING_ENG",
  },
  {
    label: "Other engineering",
    value: "OTHER_ENG",
  },
];

export const DESC_SUBCONTRACTOR_OPTIONS: ReadonlyArray<DropdownOption> = [
  {
    label: "Insulation",
    value: "INSULATION",
  },
  {
    label: "Control",
    value: "CONTROL",
  },
  {
    label: "Crane & rigging",
    value: "CRANE_RIGGING",
  },
  {
    label: "Electrical",
    value: "ELECTRICAL",
  },
  {
    label: "Fire life safety",
    value: "FIRE_LIFE_SAFETY",
  },
  {
    label: "Water treatment",
    value: "WATER_TREATMENT",
  },
  {
    label: "Roofing & waterproofing",
    value: "ROOFING",
  },
  {
    label: "Painting",
    value: "PAINTING",
  },
  {
    label: "Framing",
    value: "FRAMING",
  },
  {
    label: "General contractors",
    value: "GENERAL_CONTRACTORS",
  },
  {
    label: "Steel",
    value: "STEEL",
  },
  {
    label: "Demolition & abatement",
    value: "DEMOLITION",
  },
  {
    label: "Concrete",
    value: "CONCRETE",
  },
  {
    label: "Piping freeze/taps",
    value: "PIPING_FREEZE_TAPS",
  },
  {
    label: "Scanning & utility location",
    value: "SCANNING",
  },
  {
    label: "Coring & saw cutting",
    value: "CORING",
  },
  {
    label: "Glazing",
    value: "GLAZING",
  },
  {
    label: "Special inspections",
    value: "SPECIAL_INSPECTIONS",
  },
  {
    label: "3rd party commissioning",
    value: "3RD_PARTY_COMMISSIONING",
  },
  {
    label: "Misc subcontract",
    value: "MISC_SUBCONTRACT",
  },
];
