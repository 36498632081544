import {
  IconClock,
  IconCurrencyDollar,
  IconHammer,
  IconPigMoney,
  IconRulerMeasure,
  IconSettings2,
  IconUrgent,
  IconUsers,
} from "@tabler/icons-react";
import {
  DESC_ENGINEERING_OPTIONS,
  DESC_SUBCONTRACTOR_OPTIONS,
  dropdownDisplayStyles,
  DropdownOption,
  DropdownType,
  TRADE_OPTIONS,
} from "./Table/constants";
import styles from "./Table/DropdownCell.module.scss";

interface Returns {
  readonly displayClassName: string | undefined;
  readonly options: ReadonlyArray<DropdownOption>;
}

export function getDropdownOptions(dropdownType: DropdownType): Returns {
  const displayClassName = dropdownDisplayStyles[dropdownType];
  let options;
  switch (dropdownType) {
    case DropdownType.CostType:
      options = COST_TYPE_OPTIONS;
      break;
    case DropdownType.Trade:
      options = TRADE_OPTIONS;
      break;
    case DropdownType.DescEngineering:
      options = DESC_ENGINEERING_OPTIONS;
      break;
    case DropdownType.DescSubcontractor:
      options = DESC_SUBCONTRACTOR_OPTIONS;
      break;
  }
  return { displayClassName, options };
}

const COST_TYPE_OPTIONS: ReadonlyArray<DropdownOption> = [
  {
    className: styles.equipment,
    icon: <IconHammer size={14} />,
    label: "Equipment",
    value: "EQUIPMENT",
  },
  {
    className: styles.material,
    icon: <IconSettings2 size={14} />,
    label: "Material",
    value: "MATERIAL",
  },
  {
    className: styles.labor,
    icon: <IconSettings2 size={14} />, // TODO: find the actual icon per figma
    label: "Labor",
    value: "LABOR",
  },
  {
    className: styles.subcontractor,
    icon: <IconUsers size={14} />, // TODO: find the actual icon per figma
    label: "Subcontractor",
    value: "SUBCONTRACTOR",
  },
  {
    className: styles.engineering,
    icon: <IconRulerMeasure size={14} />, // TODO: find the actual icon per figma
    label: "Engineering",
    value: "ENGINEERING",
  },
  {
    className: styles.buffer,
    icon: <IconClock size={14} />,
    label: "PM time buffer",
    value: "PM_TIME_BUFFER",
  },
  {
    className: styles.permit,
    icon: <IconSettings2 size={14} />, // TODO: find the actual icon per figma
    label: "Permit",
    value: "PERMIT",
  },
  {
    className: styles.finders,
    icon: <IconCurrencyDollar size={14} />,
    label: "Finders fee",
    value: "FINDERS_FEE",
  },
  {
    className: styles.bond,
    icon: <IconSettings2 size={14} />, // TODO: find the actual icon per figma
    label: "Bond",
    value: "BOND",
  },
  {
    className: styles.contingency,
    icon: <IconUrgent size={14} />,
    label: "Contingency",
    value: "CONTINGENCY",
  },
  {
    className: styles.allowance,
    icon: <IconPigMoney size={14} />,
    label: "Allowance",
    value: "ALLOWANCE",
  },
  {
    className: styles.other,
    icon: <IconCurrencyDollar size={14} />,
    label: "Other",
    value: "OTHER",
  },
];
