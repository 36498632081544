import { TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { EMPTY_STRING } from "src/utils/empty";
import { DEFAULT_PLACEHOLDER, TEXT_INPUT_STYLES } from "./constants";

interface Props {
  readonly disabled?: boolean;
  readonly value?: string; // TODO: make this mandatory once hooked up to estimation table
}

export const DescriptionCell = React.memo<Props>(function _DescriptionCell({
  disabled = false,
  value = EMPTY_STRING, // TODO: update this once hooked up to estimation table
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [cellValue, setCellValue] = useInputState(value ?? EMPTY_STRING);
  const [isEdit, setIsEdit] = React.useState(false);

  const handleFocus = React.useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setIsEdit(false);

    if (cellValue !== value) {
      // TODO: do API call here
    }
  }, [cellValue, value]);

  const handleKeyPresses = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Enter":
          setIsEdit(false);
          break;
        case "ArrowRight":
          // TODO: add logic for pressing right key to autocomplete
          break;
        default:
          break;
      }
    },
    [],
  );

  React.useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isEdit]);

  return (
    <TextInput
      ref={inputRef}
      disabled={disabled}
      onBlur={handleSubmit}
      onChange={setCellValue}
      onFocus={handleFocus}
      onKeyDown={handleKeyPresses}
      placeholder={isEdit ? EMPTY_STRING : DEFAULT_PLACEHOLDER}
      styles={TEXT_INPUT_STYLES}
      value={cellValue}
      variant={isEdit ? "default" : "unstyled"}
    />
  );
});
