import { Tabs } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "src/data/api/api";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { WithClassname } from "src/types/withClassName";
import { asMutableArray } from "src/utils/asMutableArray";
import NewPackageDialog from "../NewPackageDialog";
import PackageTab from "../PackageTab";
import styles from "./EstimationTable.module.scss";
import {
  useEstimationColumnDefById,
  type DummyPackage,
} from "./hooks/useEstimationColumnDefById";

const DEFAULT_COL_DEF: ColDef<unknown> = {
  sortable: false,
};

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  suppressMovableColumns: true,
};

const NEW_PACKAGE_VALUE = "new-view";
const PACKAGE_PARAM = "packageId";

interface Props extends WithClassname {
  readonly estimationId: string;
}

const dummyPackage: ReadonlyArray<DummyPackage> = [
  {
    costType: "Engineering",
    trade: "Quoted items",
    description: "",
    quantity: {
      value: undefined,
      unit: "",
    },
    unitCost: 30.986,
    subtotal: 40048.329,
    labor: {
      trade: "Subcontracts",
      description: "this is a cool description",
      hours: 392.93,
      totalHours: 32902,
    },
  },
  {
    costType: "Allowance",
    trade: "Sheet metal",
    description: "acronyms and other info!",
    quantity: {
      value: undefined,
      unit: "",
    },
    unitCost: 150,
    subtotal: 5023.135,
    labor: {
      trade: "Subcontracts",
      description: "important descriptive info",
      hours: 3.93,
      totalHours: 874.2344,
    },
  },
  {
    costType: "Subcontractor",
    trade: "Plumbing",
    description: "",
    quantity: {
      value: undefined,
      unit: "",
    },
    unitCost: undefined,
    subtotal: undefined,
    labor: {
      trade: "Subcontracts",
      description: "",
      hours: undefined,
      totalHours: undefined,
    },
  },
];

export const EstimationTable = React.memo(function _EstimationTable({
  className,
  estimationId,
}: Props) {
  const [params, setSearchParams] = useSearchParams();
  const [isNewViewDialogOpen, { open, close }] = useDisclosure(false);

  const packages = api.endpoints.getPackages.useQuery(estimationId);

  const selectedPackage = React.useMemo(() => {
    const selectedPackageId = params.get(PACKAGE_PARAM);
    let selected: PackageId | undefined = undefined;

    if (selectedPackageId != null) {
      selected = packages.currentData?.collection.find(
        (entry) => entry.id.toString() === selectedPackageId,
      );
    }

    if (selected == null) {
      selected = packages.currentData?.collection[0];
    }

    return selected;
  }, [packages.currentData, params]);

  const handleTabChange = React.useCallback(
    (value: string | null) => {
      if (value == null) {
        return;
      }

      if (value === NEW_PACKAGE_VALUE) {
        open();
        return;
      }

      params.set(PACKAGE_PARAM, value);
      setSearchParams(params);
    },
    [open, params, setSearchParams],
  );

  const columnDefs = useEstimationColumnDefById();

  React.useEffect(() => {
    if (selectedPackage?.id != null) {
      params.set(PACKAGE_PARAM, selectedPackage.id.toString());
      setSearchParams(params);
    }
  }, [params, selectedPackage?.id, setSearchParams]);

  return (
    <div className={classNames(styles.root, "ag-theme-quartz", className)}>
      <Tabs
        activateTabWithKeyboard={false}
        className={styles.tab}
        onChange={handleTabChange}
        value={selectedPackage?.id.toString()}
        variant="outline"
      >
        <Tabs.List>
          {packages.currentData?.collection.map((packageTab) => (
            <PackageTab key={packageTab.id} packageTab={packageTab} />
          ))}
          <Tabs.Tab color="#5C5F66" value={NEW_PACKAGE_VALUE}>
            <IconPlus size={14} />
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {dummyPackage != null && (
        <AgGridReact
          columnDefs={asMutableArray(columnDefs)}
          defaultColDef={DEFAULT_COL_DEF}
          gridOptions={GRID_OPTIONS}
          // TODO: uncomment this once API set up
          // rowData={asMutableArray(selectedPackage?.attributes.bundles ?? EMPTY_ARRAY)}
          rowData={asMutableArray(dummyPackage)}
        />
      )}

      {isNewViewDialogOpen && <NewPackageDialog onClose={close} />}
    </div>
  );
});
