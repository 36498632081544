import { Input } from "@mantine/core";
import { useCallback, useState } from "react";

interface Props {
  onBlur: () => void;
  value: string;
  isEditable: boolean;
  onSubmit: (title: string) => void;
}

const EditableInput = ({ value, isEditable, onSubmit, onBlur }: Props) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && currentValue != null) {
        onSubmit(currentValue);
      }
    },
    [currentValue, onSubmit],
  );

  if (isEditable === false) {
    return value;
  }

  return (
    <Input
      autoFocus
      onBlur={onBlur}
      onChange={handleChange}
      onKeyDown={handleSubmit}
      value={currentValue}
    />
  );
};

export default EditableInput;
